@import './colors';

.btn {
  &-blue {
    background-color: $background-blue;
    color: $background-color;
  }

  &-red {
    background-color: $background-red;
    color: $background-color;
  }

  &-green {
    background-color: $background-green;
    color: $background-color;
  }

  &-yellow {
    background-color: $background-yellow;
    color: $background-color;
  }

  & > svg {
    display: inline-block;
    vertical-align: -0.125em;
    width: 1rem;
    height: 1rem;
    margin-right: 4px;
  }
}
