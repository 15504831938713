@import '../shared/colors';
@import '../shared/btn';
@import '../shared/utils';

.detail-image-wrapper {
  width: 80%;
  margin-top: 5%;
  margin-bottom: 5%;
  margin-right: auto;
  margin-left: auto;
}
.detail-description-wrapper {
  width: 80%;
  height: 50%;
  margin-top: 20%;
}

.thumbnail-large {
  // border-radius: 2rem;
  width: 100%;
  height: 100%;
}
.thumbnail-mini {
  // border-radius: 1rem;
  width: 100%;
  height: 100%;
  cursor: pointer;
  user-select: none;

  &:focus,
  &--selected {
    border-width: 4px;
    border-style: solid;
    border-color: gold;
    outline: none;
  }
}

.watermark {
  max-width: 100%;
  height: auto;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: end;

  & > span,
  img {
  }

  & > img {
    margin-right: 1rem;
  }

  & > span {
    font-size: 1rem;
    margin-right: 1rem;
  }
}

.row {
  &-toolbar {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  &-footer {
    margin-top: 1rem;
  }
}

.fullscreen {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

img.qr-code {
  width: 60%;
  height: auto;

  display: block;
  margin-left: auto;
  margin-right: auto;
}

.thumbnail-mini-margin-bottom {
  margin-bottom: 6rem;
}

img.system-image {
  width: 60%;
  height: auto;

  display: block;
  margin-left: auto;
  margin-right: auto;
}
.hidden-md.hidden-lg {
  img.system-image {
    width: 80%;
  }
}
